:root {
  --white: #fff;
  --black: #222;
  --dark-green: #2b4141;
  --light-green: #bfd99a;
  --soft-green: #e1fdae;
  --orange: #e5a500;
  --blue: #1c274c;
  --gray1: #ddd;
  --gray2: #bbb;
  --gray-text: #868686;
  --bg-gray-text: #e7e7e7;
  --green:#377050;
}
